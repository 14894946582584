import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyCsu4MAJaByb0sed8qtNeeQLEyLyJjZc1s',
  authDomain: 'getaround-servicing.firebaseapp.com',
  databaseURL: 'https://getaround-servicing.firebaseio.com',
  projectId: 'getaround-servicing',
  storageBucket: '',
  messagingSenderId: '2157450281',
};

firebase.initializeApp(config);
const auth = firebase.auth();
const db = firebase.firestore();
const Persistence = firebase.auth.Auth.Persistence;

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { auth, db, Persistence, googleAuthProvider };
