import { call, select, takeLatest } from 'redux-saga/effects';

import { Firestore } from 'state/common/sagas';
import {
  setMany as setAdmins,
  getAll as getAllAdminsStart,
} from 'state/admins/redux';
import { selectResourceList } from 'state/common/selectors';
import { selectAdminsFetched } from 'state/admins/selectors';
import { AdminEntity } from 'state/entities';
import { db } from '../../firebase/firebase';

const ADMINS = 'admins';

export function* fetchAllAdmins() {
  const fetchCall = () =>
    db
      .collection(ADMINS)
      .get()
      .then(snapshot => {
        let admins = [];
        snapshot.forEach(doc => {
          const serviceLot = doc.data().service_lot?.id;
          admins.push({
            email: doc.id,
            ...doc.data(),
            service_lot: serviceLot,
          });
        });
        return admins;
      })
      .catch(err => err);

  yield call(Firestore.fetchAllCollection, ADMINS, fetchCall, setAdmins, [
    AdminEntity,
  ]);
}

export function* fetchAllAdminsIfNecessary() {
  const fetched = yield select(selectAdminsFetched);
  if (!fetched) {
    yield call(fetchAllAdmins);
  }
  return yield select(selectResourceList, 'admins');
}

export default function* adminsSagas() {
  yield takeLatest(getAllAdminsStart.type, fetchAllAdmins);
}
