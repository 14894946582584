import React from 'react';
import { Menu } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from 'styles/tailwind';

const SidebarContent = ({ pathname }) => {
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <div className={styles('border-r h-full pt-3')}>
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="inline"
      >
        <Menu.Item key="permissions">
          <Link to="/permissions">
            <UnlockOutlined />
            Servicing App Permissions
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SidebarContent;
