import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { onNavStyleChange, toggleCollapsedSideNav } from 'state/settings/redux';
import styles from 'styles/tailwind';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';

const SidebarLogo = ({
  width,
  navCollapsed,
  navStyle,
  toggleCollapsedSideNav,
  onNavStyleChange,
}) => {
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  return (
    <div className={styles('gx-layout-sider-header')}>
      <div className={styles('gx-linebar p-3')}>
        <i
          className={styles(
            'cursor-pointer',
            `gx-icon-btn icon icon-${
              navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'
            }`,
          )}
          onClick={() => {
            if (navStyle === NAV_STYLE_DRAWER) {
              toggleCollapsedSideNav(!navCollapsed);
            } else if (navStyle === NAV_STYLE_FIXED) {
              onNavStyleChange(NAV_STYLE_MINI_SIDEBAR);
            } else {
              onNavStyleChange(NAV_STYLE_FIXED);
            }
          }}
        />
      </div>

      <Link to="/" className="gx-site-logo">
        <img
          alt="getaround logo"
          width="196px"
          src={require('assets/images/Getaround_Logo_041819_Final_Wordmark Small-368x135-a90f736.png')}
        />
      </Link>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { navStyle, navCollapsed, width, pathname } = settings;
  return { navStyle, navCollapsed, width, pathname };
};

const actionCreators = {
  toggleCollapsedSideNav,
  onNavStyleChange,
};

export default connect(mapStateToProps, actionCreators)(SidebarLogo);
