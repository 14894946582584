import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import reducers, { rootSaga } from '.';

const createHistory = require('history').createBrowserHistory;

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware, routeMiddleware],
});

sagaMiddleware.run(rootSaga);

if (module.hot) {
  // Enable Webpack HMR for reducers
  module.hot.accept('../state', () => {
    const nextRootReducer = require('.');
    store.replaceReducer(nextRootReducer);
  });
}

export { history };
export default store;
