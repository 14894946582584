import React, { Component } from 'react';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainApp from './MainApp';
import SignIn from 'features/SignIn';
import SignUp from 'features/SignUp';
import { setInitUrl } from 'state/auth/redux';
import { onNavStyleChange } from 'state/settings/redux';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  setLayoutType = () => {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  };

  setNavStyle = () => {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  };

  componentDidMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
  }

  render() {
    const {
      match,
      location,
      layoutType,
      navStyle,
      authUser,
      initURL,
    } = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        return <Redirect to={'/signin'} />;
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return <Redirect to={'/permissions'} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    this.setLayoutType(layoutType);
    this.setNavStyle(navStyle);

    return (
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <RestrictedRoute
          path={`${match.url}`}
          authUser={authUser}
          component={MainApp}
        />
      </Switch>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, layoutType } = settings;
  const { authUser, initURL } = auth;
  return { navStyle, layoutType, authUser, initURL };
};
export default connect(mapStateToProps, {
  setInitUrl,
  onNavStyleChange,
})(App);
