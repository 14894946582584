import React, { useEffect } from 'react';
import { Button, Checkbox, Input, Form } from 'antd';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import {
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
  userSignUp,
} from 'state/auth/redux';

import { message } from 'antd/lib/index';
import CircularProgress from 'components/CircularProgress';

const { Item } = Form;

const SignUp = ({
  userSignUp,
  hideMessage,
  showAuthLoader,
  history,
  loader,
  alertMessage,
  showMessage,
  authUser,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(values => {
      showAuthLoader();
      userSignUp(values);
    });
  };

  const handleSignUp = () => {
    if (showMessage) {
      setTimeout(() => {
        hideMessage();
      }, 100);
    }
    if (authUser !== null && history.location.pathname === '/signup') {
      history.push('/');
    }
  };

  useEffect(handleSignUp, [showMessage, authUser]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg" />
            <div className="gx-app-logo">
              <img
                alt="Getaround"
                src={require('assets/images/Getaround_Wordmark_White_Digital-231x50-03fe862.png')}
                height="25px"
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              form={form}
              onFinish={handleSubmit}
              className="gx-signup-form gx-form-row0"
            >
              <Item
                name="userName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Item>
              <Item
                htmlFor="email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not a valid E-mail!',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Item>
              <Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Item>
              <Item name="remember" valuePropName="checked">
                <>
                  <Checkbox>by signing up, I accept the</Checkbox>
                  <span className="gx-link gx-signup-form-forgot">
                    Terms & Conditions
                  </span>
                </>
              </Item>
              <Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  Sign Up
                </Button>
                <span>or</span> <Link to="/signin">Sign In</Link>{' '}
              </Item>
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

const actionCreators = {
  userSignUp,
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
};

export default connect(mapStateToProps, actionCreators)(SignUp);
