import { NAV_STYLE, NAV_STYLE_FIXED } from '../../constants/ThemeSetting';
import { SIGNOUT_USER_SUCCESS } from 'state/auth/redux';

export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  pathname: '',
};

const settingsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.pathname,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case SIGNOUT_USER_SUCCESS:
      return initialSettings;
    default:
      return state;
  }
};

export default settingsReducer;
