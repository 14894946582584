import React from 'react';
import loader from 'assets/images/loader.svg';
import styles from 'styles/tailwind';

const CircularProgress = () => (
  <div className={styles('flex justify-center')}>
    <img src={loader} alt="loader" />
  </div>
);
export default CircularProgress;
