import { all, call, takeLatest } from 'redux-saga/effects';

import { Firestore } from 'state/common/sagas';
import {
  add as addPhoneStart,
  set as setPhone,
  setMany as setPhones,
  getAll as getAllPhonesStart,
  revoke as revokePhoneStart,
  reinstate as reinstatePhoneStart,
  reset as resetPhones,
} from 'state/phones/redux';
import { fetchAllAdminsIfNecessary } from 'state/admins/sagas';
import { fetchAllServiceLotsIfNecessary } from 'state/service_lots/sagas';
import { fetchCurrentUser } from 'state/auth/sagas';
import { PhoneEntity } from 'state/entities';
import { db } from '../../firebase/firebase';

const PHONES = 'phones';

export function* fetchPhone({ id }) {
  const fetchCall = () =>
    db
      .collection(PHONES)
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.data();
        return {
          ...data,
          number: doc.id,
          manager: data.manager?.id,
        };
      })
      .catch(err => err);

  yield call(Firestore.fetchDocument, PHONES, fetchCall, setPhone, PhoneEntity);
}

export function* addPhone({ payload }) {
  const manager = yield call(fetchCurrentUser);

  const {
    contact_first_name,
    contact_last_name,
    phone_number,
    contact_email,
  } = payload;

  const postData = {
    contact_first_name,
    contact_last_name,
    contact_email,
    manager: manager.ref,
    active: true,

    // TODO: Hardcoded fields to be removed in FT-39
    fleetio_account_name: 'Fleet Manager',
    fleetio_account_token: 'beaea169a5',
    fleetio_contact_id: null,
    fleetio_user: null,
    fleetio_user_name: 'rkgetaroundt1_staging',
    user_role: 'FleetManager',
  };

  const addPhoneCall = () => {
    const phoneDoc = db.collection(PHONES).doc(phone_number);

    phoneDoc.get().then(docSnapshot => {
      if (docSnapshot.exists) {
        phoneDoc.update(postData);
      } else {
        phoneDoc.set(postData);
      }
    });
  };

  yield call(Firestore.postDocument, PHONES, addPhoneCall);
  yield call(fetchPhone, { id: phone_number });
}

function* reinstatePhone({ payload }) {
  const { phoneNumber } = payload;
  const data = { active: true };
  yield call(patchPhone, { phoneNumber, data });
}

function* revokePhone({ payload }) {
  const { phoneNumber } = payload;
  const data = { active: false };
  yield call(patchPhone, { phoneNumber, data });
}

export function* patchPhone({ phoneNumber, data }) {
  const patchCall = () =>
    db
      .collection(PHONES)
      .doc(phoneNumber)
      .update(data);

  yield call(Firestore.patchDocument, PHONES, patchCall, resetPhones);
  yield call(fetchPhones);
}

export function* fetchPhones() {
  const fetchCall = () =>
    db
      .collection(PHONES)
      .get()
      .then(snapshot => {
        let phones = [];
        snapshot.forEach(doc => {
          phones.push({
            number: doc.id,
            ...doc.data(),
            manager: doc.data().manager?.id,
          });
        });
        return phones;
      })
      .catch(err => err);

  yield all([
    call(fetchAllAdminsIfNecessary),
    call(fetchAllServiceLotsIfNecessary),
  ]);

  yield call(Firestore.fetchAllCollection, PHONES, fetchCall, setPhones, [
    PhoneEntity,
  ]);
}

export default function* phonesSagas() {
  yield takeLatest(addPhoneStart.type, addPhone);
  yield takeLatest(reinstatePhoneStart.type, reinstatePhone);
  yield takeLatest(revokePhoneStart.type, revokePhone);
  yield takeLatest(getAllPhonesStart.type, fetchPhones);
}
