import { select, put, call } from 'redux-saga/effects';
import { fetchSuccess, fetchStart, fetchError } from 'state/common/redux';

function fetchAllResource(
  resourceName,
  apiResource,
  successAction,
  selector,
  schema,
) {
  return function* list() {
    let state = [];
    try {
      state = yield select(selector);
    } catch (error) {
      console.log(`Error loading state for resource ${resourceName}`, error);
    }
    if (state?.length > 0) {
      console.log(`${resourceName} already fetched`);
      return state;
    } else {
      console.log(`${resourceName} = ${state}`);
    }

    yield put(fetchStart({ resource: resourceName }));
    try {
      const results = yield call(apiResource.list.bind(apiResource));

      let successPayload = {};
      successPayload[resourceName.toLowerCase()] = results;
      yield put({ type: successAction, payload: successPayload });
      yield put(fetchSuccess({ resource: resourceName }));
      return results;
    } catch (error) {
      yield put(fetchError({ error, resource: resourceName }));
      throw error;
    }
  };
}

function fetchPageOfResource(resourceName, apiResource, successAction, schema) {
  return function* list({ cursor, options }) {
    yield put(fetchStart({ resource: resourceName }));
    try {
      const results = yield call(apiResource.list.bind(apiResource), {
        cursor,
        options,
      });

      yield put(successAction({ [resourceName]: results }));
      yield put(fetchSuccess({ resource: resourceName }));
      return results;
    } catch (error) {
      yield put(fetchError({ error, resource: resourceName }));
      throw error;
    }
  };
}

function fetchResource(resourceName, apiResource, successAction, schema) {
  return function* get(id) {
    yield put(fetchStart({ id, resource: resourceName }));
    try {
      const result = yield call(apiResource.get.bind(apiResource), id);

      let successPayload = {};
      successPayload[resourceName.toLowerCase()] = result;
      yield put({ type: successAction, payload: successPayload });
      yield put(fetchSuccess({ resource: resourceName }));
      return result;
    } catch (error) {
      yield put(fetchError({ error, resource: resourceName }));
      throw error;
    }
  };
}

function patchResource(resourceName, apiResource, successAction, schema) {
  return function* update(id, payload) {
    yield put(fetchStart({ resource: resourceName, id, payload }));

    try {
      const result = yield call(apiResource.update.bind(apiResource), payload);

      yield put(successAction({ [resourceName]: result }));
      yield put(fetchSuccess({ resource: resourceName }));
      return result;
    } catch (error) {
      yield put(fetchError({ error, resource: resourceName }));
      throw error;
    }
  };
}

function postResource(resourceName, apiResource, successAction, schema) {
  return function* create(payload) {
    yield put(fetchStart({ resource: resourceName, payload }));

    try {
      const result = yield call(apiResource.post.bind(apiResource), payload);

      yield put(successAction({ [resourceName]: result }));
      yield put(fetchSuccess({ resource: resourceName }));
      return result;
    } catch (error) {
      yield put(fetchError({ error, resource: resourceName }));
      throw error;
    }
  };
}

export default {
  fetchAllResource,
  fetchPageOfResource,
  fetchResource,
  patchResource,
  postResource,
};
