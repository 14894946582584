import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'utils/asyncComponent';

const Routes = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        exact
        path={`${match.url}permissions`}
        component={asyncComponent(() => import('features/Permissions'))}
      />
    </Switch>
  </div>
);

export default Routes;
