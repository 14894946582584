import { createSelector } from 'reselect';
import { selectCommonResource } from 'state/common/selectors';
import { status } from 'state/common/constants';

export const selectServiceLotsState = state => state.service_lots;

export const selectServiceLotsFetched = createSelector(
  state => selectCommonResource(state, 'service_lots'),
  serviceLotsStatus => serviceLotsStatus === status.SUCCESS,
);
