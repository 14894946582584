import { createSlice, createAction } from '@reduxjs/toolkit';
import union from 'lodash/union';

import { resourceDefaultState } from 'state/common/constants';

const { reducer, actions } = createSlice({
  name: 'phones',
  initialState: resourceDefaultState,
  reducers: {
    set: (state, { payload }) => {
      const { entities, result } = payload;

      state.entities = Object.assign({}, state.entities, entities.phones);
      state.list = union(state.list, [result]);
    },
    setMany: (state, { payload }) => {
      const { entities, result } = payload;

      state.entities = Object.assign({}, state.entities, entities.phones);
      state.list = union(state.list, result);
    },
    reset: () => resourceDefaultState,
  },
});

export const add = createAction('phones/add');
export const getAll = createAction('phones/getAll');
export const revoke = createAction('phones/revoke');
export const reinstate = createAction('phones/reinstate');

export const { set, setMany, reset } = actions;

export default reducer;
