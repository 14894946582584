import { createSlice } from '@reduxjs/toolkit';
import some from 'lodash/some';
import values from 'lodash/values';

const initialState = {
  error: '',
  loading: false,
  message: '',
  resources: {},
};

export const FETCHING = 'FETCHING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

const isLoading = resources =>
  some(values(resources), resource => resource === FETCHING);

const { reducer, actions } = createSlice({
  name: 'common',
  initialState,
  reducers: {
    fetchStart: (state, { payload }) => {
      let resources = state.resources;
      if (payload?.resource) {
        resources = { ...state.resources, [payload.resource]: FETCHING };
      }
      state.loading = isLoading(resources);
      state.resources = resources;
    },
    fetchSuccess: (state, { payload }) => {
      let resources = state.resources;
      if (payload?.resource) {
        resources = { ...state.resources, [payload.resource]: SUCCESS };
      }
      state.loading = isLoading(resources);
      state.resources = resources;
    },
    fetchError: (state, { payload }) => {
      let resources = state.resources;
      if (payload?.resource) {
        resources = { ...state.resources, [payload.resource]: ERROR };
      }
      state.loading = isLoading(resources);
      state.error = payload.error;
      state.resources = resources;
    },
    showMessage: (state, { payload }) => {
      state.message = payload;
    },
    hideMessage: state => {
      state.message = '';
    },
    signoutSuccess: () => initialState,
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  showMessage,
  hideMessage,
  signoutSuccess,
} = actions;

export default reducer;
