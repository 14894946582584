import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Layout } from 'antd';

import SidebarContent from './SidebarContent';
import { onNavStyleChange, toggleCollapsedSideNav } from 'state/settings/redux';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import SidebarLogo from './SidebarLogo';

const { Sider } = Layout;

const Sidebar = ({
  navCollapsed,
  width,
  navStyle,
  pathname,
  toggleCollapsedSideNav,
}) => (
  <Sider
    trigger={null}
    collapsed={width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR}
    collapsible
  >
    {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
      <Drawer
        placement="left"
        closable={false}
        onClose={() => {
          toggleCollapsedSideNav(!this.props.navCollapsed);
        }}
        visible={navCollapsed}
      >
        <>
          <SidebarLogo />
          <SidebarContent pathname={pathname} />
        </>
      </Drawer>
    ) : (
      <>
        <SidebarLogo />
        <SidebarContent pathname={pathname} />
      </>
    )}
  </Sider>
);

const mapStateToProps = ({ settings }) => {
  const { navStyle, navCollapsed, width, pathname } = settings;
  return { navStyle, navCollapsed, width, pathname };
};

const actionCreators = {
  toggleCollapsedSideNav,
  onNavStyleChange,
};

export default connect(mapStateToProps, actionCreators)(Sidebar);
