import React from 'react';
import { hot } from 'react-hot-loader';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import 'assets/vendors/style';
import 'styles/less/wieldy.less';
import 'styles/tailwind.css';

import store, { history } from '../../state/configureStore';
import '../../firebase/firebase';
import App from './App';

const AppContainer = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default hot(module)(AppContainer);
