import React, { Component } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import { toggleCollapsedSideNav } from '../../state/settings/redux';
import UserInfo from 'components/UserInfo';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import { connect } from 'react-redux';

const { Header } = Layout;

class Topbar extends Component {
  state = {
    searchText: '',
  };

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value,
    });
  };

  render() {
    const { width, navCollapsed, navStyle } = this.props;
    return (
      <Header>
        {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                this.props.toggleCollapsedSideNav(!navCollapsed);
              }}
            />
          </div>
        ) : null}
        <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
          <img
            alt=""
            width="25px"
            src={require('assets/images/Getaround_Logo_Black_Logo Stamo-50x50-c82bd73.png')}
          />
        </Link>

        <ul className="gx-header-notifications gx-ml-auto">
          {width >= TAB_SIZE ? null : (
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          )}
        </ul>
      </Header>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, navCollapsed, width } = settings;
  return { locale, navStyle, navCollapsed, width };
};

export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
})(Topbar);
