import { createSelector } from 'reselect';
import { selectCommonResource } from 'state/common/selectors';
import { status } from 'state/common/constants';

export const selectAdminsState = state => state.admins;

export const selectAdminsFetched = createSelector(
  state => selectCommonResource(state, 'admins'),
  adminsStatus => adminsStatus === status.SUCCESS,
);
