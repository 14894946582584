import React, { useEffect } from 'react';
import { Form, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
  userSignIn,
} from 'state/auth/redux';
import CircularProgress from 'components/CircularProgress';

const { Item } = Form;

const SignIn = ({
  showAuthLoader,
  userSignIn,
  showMessage,
  hideMessage,
  authUser,
  history,
  loader,
  alertMessage,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(values => {
      showAuthLoader();
      userSignIn(values);
    });
  };

  const handleSignIn = () => {
    if (showMessage) {
      setTimeout(() => {
        hideMessage();
      }, 100);
    }
    if (authUser !== null && history.location.pathname === '/signin') {
      history.push('/');
    }
  };

  useEffect(handleSignIn, [showMessage, authUser]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg" />
            <div className="gx-app-logo">
              <img
                alt="Getaround"
                src={require('assets/images/Getaround_Wordmark_White_Digital-231x50-03fe862.png')}
                height="25px"
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              form={form}
              onFinish={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Item>
              <Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Item>
              <Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  Sign In
                </Button>
                <span>or</span> <Link to="/signup">Sign Up</Link>
              </Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

const actionCreators = {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
};

export default connect(mapStateToProps, actionCreators)(SignIn);
