import { schema } from 'normalizr';

export const ServiceLotEntity = new schema.Entity('service_lots');

// 'Admin' refers to a user which manages access
// for 'Phones'
export const AdminEntity = new schema.Entity(
  'admins',
  {
    service_lot: ServiceLotEntity,
  },
  {
    idAttribute: 'email',
  },
);

// 'Phone' refers to a phone which can download
// the servicing app to unlock cars
export const PhoneEntity = new schema.Entity(
  'phones',
  {
    manager: AdminEntity,
  },
  {
    idAttribute: 'number',
  },
);
