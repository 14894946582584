import { call, select, takeLatest } from 'redux-saga/effects';

import { Firestore } from 'state/common/sagas';
import {
  setMany as setServiceLots,
  getAll as getAllServiceLotsStart,
} from 'state/service_lots/redux';
import { selectResourceList } from 'state/common/selectors';
import { selectServiceLotsFetched } from 'state/service_lots/selectors';
import { ServiceLotEntity } from 'state/entities';
import { db } from '../../firebase/firebase';

const SERVICE_LOTS = 'service_lots';

export function* fetchAllServiceLots() {
  const fetchCall = () =>
    db
      .collection(SERVICE_LOTS)
      .get()
      .then(snapshot => {
        let lots = [];
        snapshot.forEach(doc => lots.push({ id: doc.id, ...doc.data() }));
        return lots;
      })
      .catch(err => err);

  yield call(
    Firestore.fetchAllCollection,
    SERVICE_LOTS,
    fetchCall,
    setServiceLots,
    [ServiceLotEntity],
  );
}

export function* fetchAllServiceLotsIfNecessary() {
  const fetched = yield select(selectServiceLotsFetched);
  if (!fetched) {
    yield call(fetchAllServiceLots);
  }
  return yield select(selectResourceList, SERVICE_LOTS);
}

export default function* serviceLotsSagas() {
  yield takeLatest(getAllServiceLotsStart.type, fetchAllServiceLots);
}
