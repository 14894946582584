import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  auth,
  db,
  googleAuthProvider,
  Persistence,
} from '../../firebase/firebase';
import {
  SIGNIN_GOOGLE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
} from 'state/auth/redux';
import {
  showAuthMessage,
  userInfoSuccess,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from 'state/auth/redux';
import { userGoogleSignInSuccess } from 'state/auth/redux';
import { eventChannel } from '@redux-saga/core';
import { take } from '@redux-saga/core/effects';

export function* fetchCurrentUser() {
  return yield db
    .collection('admins')
    .doc(auth.currentUser.email)
    .get();
}

const createUserWithEmailPasswordRequest = async (email, password) => {
  await auth.setPersistence(Persistence.LOCAL);
  return await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
};

const signInUserWithEmailPasswordRequest = async (email, password) => {
  await auth.setPersistence(Persistence.LOCAL);
  return await auth
    .signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
};

const signOutRequest = async () => await auth.signOut().catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password,
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password,
    );
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem('user_id', signInUser.user.uid);
      signInUser.user.getIdToken().then(token => {
        localStorage.setItem('user_token', token);
      });
      yield put(userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function allAuthStateChanges() {
  return eventChannel(emitter => {
    return auth.onAuthStateChanged(user => {
      if (user) {
        db.collection('admins')
          .doc(user.email)
          .onSnapshot(admin => {
            emitter({ user, admin, action: 'SIGN_IN' });
          });
      } else {
        emitter({ user: null, admin: null, action: 'SIGN_OUT' });
      }
    });
  });
}

function* fetchEveryUserInfo() {
  const chan = yield call(allAuthStateChanges);

  try {
    while (true) {
      let { user, admin, action } = yield take(chan);
      if (action === 'SIGN_IN') {
        yield put(userInfoSuccess({ user, admin }));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signOutUser),
    fork(fetchEveryUserInfo),
  ]);
}
