import { put, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { fetchSuccess, fetchStart, fetchError } from 'state/common/redux';

function* fetchAllCollection(collectionName, fetchCall, successAction, schema) {
  yield put(fetchStart({ resource: collectionName }));

  try {
    const results = yield call(fetchCall);
    const { entities, result } = normalize(results, schema);

    yield put(successAction({ entities, result }));
    yield put(fetchSuccess({ resource: collectionName }));
    return results;
  } catch (error) {
    yield put(fetchError({ error, resource: collectionName }));
  }
}

function* fetchDocument(collectionName, fetchCall, successAction, schema) {
  yield put(fetchStart({ resource: collectionName }));

  try {
    const document = yield call(fetchCall);
    const { entities, result } = normalize(document, schema);

    yield put(successAction({ entities, result }));
    yield put(fetchSuccess({ resource: collectionName }));
  } catch (error) {
    yield put(fetchError({ error, resource: collectionName }));
  }
}

function* postDocument(collectionName, postCall) {
  yield put(fetchStart({ resource: collectionName }));

  try {
    yield call(postCall);

    yield put(fetchSuccess({ resource: collectionName }));
  } catch (error) {
    yield put(fetchError({ error, resource: collectionName }));
  }
}

function* patchDocument(collectionName, patchCall, successAction) {
  yield put(fetchStart({ resource: collectionName }));

  try {
    yield call(patchCall);

    yield put(successAction());
    yield put(fetchSuccess({ resource: collectionName }));
  } catch (error) {
    yield put(fetchError({ error, resource: collectionName }));
  }
}

export default {
  fetchAllCollection,
  fetchDocument,
  postDocument,
  patchDocument,
};
