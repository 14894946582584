import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { all, fork } from 'redux-saga/effects';

import { authSagas, authReducer } from './auth';
import { phonesSagas, phonesReducer } from './phones';
import { adminsSagas, adminsReducer } from './admins';
import { serviceLotsSagas, serviceLotsReducer } from './service_lots';
import { commonReducer } from './common';
import { settingsReducer } from './settings';

const reducers = combineReducers({
  routing: routerReducer,
  settings: settingsReducer,
  auth: authReducer,
  phones: phonesReducer,
  admins: adminsReducer,
  service_lots: serviceLotsReducer,
  common: commonReducer,
});

export function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(adminsSagas),
    fork(phonesSagas),
    fork(serviceLotsSagas),
  ]);
}

export default reducers;
