import { createSlice, createAction } from '@reduxjs/toolkit';
import union from 'lodash/union';

import { resourceDefaultState } from 'state/common/constants';

const { reducer, actions } = createSlice({
  name: 'admins',
  initialState: resourceDefaultState,
  reducers: {
    setMany: (state, { payload }) => {
      const { entities, result } = payload;

      state.entities = Object.assign({}, state.entities, entities.admins);
      state.list = union(state.list, result);
    },
  },
});

export const getAll = createAction('admins/getAll');

export const { setMany } = actions;

export default reducer;
