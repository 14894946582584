export const status = {
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  FETCHING: 'FETCHING',
  PATCHING: 'PATCHING',
  CREATING: 'CREATING',
  DELETING: 'DELETING',
  PAGINATING: 'PAGINATING',
  ERROR: 'ERROR',
};

export const resourceDefaultState = {
  entities: {},
  list: [],
};
